<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.app.appVersion.title.editHeadTitle') : $t('cip.plat.app.appVersion.title.addHeadTitle')"
                 @head-save="headSave()"
                 @head-save-cancel="headSave(true)"
                 @head-cancel="headCancel">
    </head-layout>
    <form-layout :column.sync="column" ref="formLayout" :dataForm.sync="dataForm" v-loading="formLoading"></form-layout>
    <!--    <div>-->
    <!--      <img :src="this.imageUrl" alt=""/>-->
    <!--    </div>-->
  </el-main>
</template>
<script>
import {getDetail, saveOrUpdate, getQRcode} from "@/api/system/appVersion";
import FormLayout from "@/views/components/layout/form-layout";
import ServerNameEnum from '@/util/ServerNameEnum';
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";

export default {
  components: {FormLayout, HeadLayout},
  data() {
    return {
      // imageUrl: '',
      isClose: false,
      itemData: [],
      viewFlag: true,//是否展示按钮
      formLoading: false,
      type: 'view',
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
    headBtnOptions() {
      let res = [];
      if (['edit', 'add'].includes(this.type)) {
        res.push({
          btnOptType: 'save',
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: 'head-save',
        });
        res.push({
          btnOptType: 'saveBack',
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: 'head-save-cancel',
        });
      }
      res.push({
        btnOptType: 'cancel',
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: 'head-cancel',
      });
      return res;
    },
    column() {
      return [
        {
          label: this.$t('cip.plat.app.appVersion.field.versionCode'),
          prop: "versionCode",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          readonly: this.viewFlag,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appVersion.field.versionCode'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
          placeholder: this.$t("cip.plat.app.appVersion.field.versionCode"),
        },
        {
          label: this.$t('cip.plat.app.appVersion.field.versionName'),
          prop: "versionName",
          span: 8,
          readonly: this.viewFlag,
          isGlobal: true, //是否支持国际化
          rules: [
            {
              required: true,
              validator: this.validateName,
              trigger: "blur"
            }],
          placeholder: this.$t("cip.plat.app.appVersion.field.versionName"),
        },
        {
          label: this.$t('cip.plat.app.appVersion.field.updateType'),
          prop: "updateType",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=app_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          headerAlign: "center",
          align: 'center',
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appVersion.field.updateType'),
              trigger: "blur"
            }
          ],
          placeholder: this.$t("cip.plat.app.appVersion.field.updateType"),
        },
        {
          label: this.$t('cip.plat.app.appVersion.field.downloadUrl'),
          prop: 'downloadUrl',
          type: 'upload',
          dataType: 'string',
          drag: true,
          loadText: this.$t('cip.cmn.rule.photograph'),
          propsHttp: {
            url: 'link',
            name: 'name',
            res: 'data'
          },
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          span: 16,
          change: ({value, column}) => {
            let split = value.split(",");
            console.log(split);
            if (split.length > 1) {
              this.dataForm.downloadUrl = split[1];
            }
          },
          row: true,
          readonly: this.viewFlag,
          rules: [{
            required: true,
            message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appVersion.field.downloadUrl'),
            trigger: "blur"
          }],
        },
        {
          label: this.$t('cip.plat.app.appVersion.field.versionInfo'),
          prop: "versionInfo",
          type: 'content',
          labelWidth: 130,
          span: 24,
          maxlength: 20,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
          minRows: 6,
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appVersion.field.versionInfo'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
      ];
    },

  },
  methods: {
    //保存
    headSave(clo) {
      this.$refs.formLayout.getForm().validate(valid => {
        if (valid) {
          this.$loading();
          this.formLoading = true;
          saveOrUpdate(this.dataForm).then(res => {
            let {
              msg,
              data,
            } = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.dataForm = data;
            this.type = 'edit';
            if (clo) {
              this.headCancel()
            }
          }).finally(() => {
            this.$loading().close();
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.formLoading = false;
          })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      //加载主表信息
      getDetail(id).then(res => {
        this.dataForm = res.data.data;
      })
    },
    // getQRcode() {
    //   getQRcode().then(res => {
    //     this.imageUrl = res.data.data;
    //   })
    // },
    //国际化字段：App名称的校验
    validateName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 30) {
          callback(new Error(this.$t("cip.plat.app.appVersion.field.versionName") + this.$t("cip.cmn.rule.nameLength3Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appVersion.field.versionName")));
      }
    },
  },
  created() {
    // this.getQRcode();
    this.type = this.$route.query.type;
    if (this.type == 'add') {
      this.viewFlag = false;
    } else if (this.type == 'edit') {
      this.viewFlag = false;
      this.formData(this.$route.query.id);
    } else if (this.type == 'view') {
      this.viewFlag = true;
      this.formData(this.$route.query.id);
    }
  },
};
</script>

<style scoped>

</style>
